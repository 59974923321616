import React, { useState, useRef } from 'react';

import { isJSON, TeleScript } from 'sg-ui-components';
import { Overlay } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import './my-prizes.scss';

/**********************************************************************
 * Component: ThirdPartyPrize
 * Purpose:   Display's the third party (Mariz) Coupons
 *
 * Props: - thirdPartyPrizes - third Party Prize store
 *          actions       -  actions table for api calls
 *          telesctipt    - telescript object for overriding static texts with the CMS
 *
 * APIs used:  /api/player/prizes - get the list of prizes along with their third party coupons
 *             /query/thirdPartyPrizes/${urlArgs.barcode} - get the Martiz redirtect info for the specific barcode
 *             Maritz Redirection Servelet -  Allows us to get the URL for the user to select their prize
 *
 */

const ThirdPartyPrize = ({ entry, telescript, thirdPartyPrizes, actions }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const maritzRef = useRef(null);

    const showOverlay = (e) => {
        target.current = e.target;
        setShow(!show);
    };

    const handleViewPrize = async (e, barcode) => {
        e.preventDefault();
        await actions.thirdPartyPrizeActions.getThirdPartyPrizeBarcode({ barcode: barcode });
        maritzRef.current.submit();
    };

    // State can be AVAILABLE, HELD, ASSIGNED, REDEEMED, EXPIRED
    // Maritz requires that a form be sent to them to properly redirect the user to the Martiz store.
    if (entry.State && entry.State.toUpperCase() === 'ASSIGNED') {
        return (
            <>
                <form action={thirdPartyPrizes.redirectUrl} method='post' target='_blank' name='maritzForm' ref={maritzRef}>
                    <input id='PartnerIdentifier' name='PartnerIdentifier' type='hidden' value={thirdPartyPrizes.partnerIdentifier} />
                    <input id='ClientIdentifier' name='ClientIdentifier' type='hidden' value={thirdPartyPrizes.clientIdentifier} />
                    <input id='clientProjectKey' name='clientProjectKey' type='hidden' value={thirdPartyPrizes.clientProjectKey} />
                    <input id='redirectToken' name='redirectToken' type='hidden' value={thirdPartyPrizes.redirectToken} />
                    <button className='btn btn-link' onClick={(e) => handleViewPrize(e, entry.Barcode.SerialNo)}>
                        <TeleScript line={telescript?.selectMyPrizeNow}>Select My Prize Now</TeleScript>
                    </button>
                </form>
            </>
        );
    } else if (entry.State && entry.State.toUpperCase() === 'REDEEMED') {
        const prizeDescription = isJSON(entry?.extended_attributes)
            ? JSON.parse(entry?.extended_attributes)?.prize_item_description
            : entry?.extended_attributes?.prize_item_description;

        if (prizeDescription) {
            return (
                <>
                    <Overlay target={target.current} show={show} placement='bottom'>
                        <Popover className='prizePopover'>
                            <TeleScript line={telescript?.selectedPrize}>
                                <h3>Selected Prize</h3>
                            </TeleScript>
                            <hr />
                            <p dangerouslySetInnerHTML={{ __html: prizeDescription ?? null }} />
                        </Popover>
                    </Overlay>
                    <button type='button' className='btn btn-link' onClick={showOverlay}>
                        <TeleScript line={telescript?.redeemedSeeDetails}>Redeemed - See Details</TeleScript>
                    </button>
                </>
            );
        } else
            return (
                <TeleScript line={telescript?.prizeDetailsNotAvailable}>
                    <p>Prize details not available</p>
                </TeleScript>
            );
    } else {
        return entry.State;
    }
};
export default ThirdPartyPrize;
